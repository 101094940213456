const header = {
  "accept"        : "application/json",
  "aplication"    : "application/json",
  "Content-Type"  : "application/json"
}

const UsuarioApi = {
  BuscarEmpresasAtivas : async () => {
    try{
      const url = `${process.env.REACT_APP_URL_API}/usuarios/empresasAtivas/`;
      const req = await fetch(url, {
        method    : "GET",
        headers   : header,
      });

      const res = await req.json();
      //console.log("Aqui ó", res);
      return { status: true, req: res };
    } catch (e) {
      return { status: false, req: e };
    }
  },
  BuscarLinhasAtivas : async () => {
    try{
      const url = `${process.env.REACT_APP_URL_API}/usuarios/linhasAtivas/`;
      const req = await fetch(url, {
        method    : "GET",
        headers   : header,
      });

      const res = await req.json();
      console.log("Aqui ó", res);
      return { status: true, req: res };
    } catch (e) {
      return { status: false, req: e };
    }
  },
  CadastrarMotorista : async ( body ) => {
    try{
      const url = `${process.env.REACT_APP_URL_API}/auth/cadastrar/`;
      const req = await fetch(url, {
        method    : "POST",
        headers   : header,
        body      : JSON.stringify(body),
      });
      
      const res = await req.json();
      console.log("Cadastrar Usuario", res);
      return { status: true, req: res };
    } catch (e) {
      return { status: false, req: e };
    }
  },
  AutenticarUsuario   : async ( email, senha ) => {
    try {
      const url = `${process.env.REACT_APP_URL_API}/auth/autenticar/`;
      const req = await fetch(url, {
        method    : "POST",
        headers   : header,
        body      : JSON.stringify({ email, senha }),
      });
      const res = await req.json();
      console.log("Autenticar Usuario", res);
      return { status: true, req: res };
    } catch (e) {
      return { status: false, req: e };
    }
  },
  EnviarOperacao      : async ( body ) => {
    try {
      console.log("Enviando Operação", body);
      const url = `${process.env.REACT_APP_URL_API}/operacaoRegistrar/`;
      const req = await fetch(url, {
        method    : "POST",
        headers   : header,
        body      : JSON.stringify(body),
      });
      const res = await req.json();
      console.log("Enviar Operacao", res);
      return { status: true, req: res };
    } catch (e) {
      return { status: false, req: e };
    }
  }
};

export default UsuarioApi;