import React          from "react";

import { 
  Loading, CompSelect, Alerts
} from "../../components/Basics";
import socketIoClient             from "socket.io-client";
import { UsuarioApi }             from "../../servicesAPI";
import { Loader }                 from "@googlemaps/js-api-loader"
import { gpsBus, gpsUser }        from "../../assets";
import { AplicationContext }      from "../../contexts/AplicationContext";
import { rotaC_move, rotaD_move, rotaE_move } from "../../RotaC_D";
import "./stylesAM.css";
import messagens from "../../store/StringsMessagesAplication";
import { Services } from "../../services";

export default function AcessMotorista() {

  const mapRef = React.useRef(null);
  const mapInstanceRef = React.useRef(null);

  const {
    state, actions, mode
  } = React.useContext(AplicationContext);

  const [socket, setSocket]               = React.useState(null);         //socket para o serviço de rotas
  const [sttPg, setSttPg]                 = React.useState({
    empresas   : [],
    empresaSel : "",
    linhaSel   : "",
    // posição inicial vazia
    position   : {lat: 0, lng: 0},
  }); //estado da página
  const [loading, setLoading]             = React.useState(true);         //loading da tela
  //console.log(sttPg);
  const [rotaC, setRotaC]                 = React.useState(rotaC_move);   //rota C
  const [rotaD, setRotaD]                 = React.useState(rotaE_move);   //rota D
  const [userMarkers, setMarkers]         = React.useState(null);         //marcador do usuário no mapa
  const [veicleMarker, setVeicleMarker]   = React.useState(null);         //marcadores no mapa demais veículos
  
  console.log("SttPg: ", sttPg);
  console.log("RotaC: ", rotaC.length);
  console.log("RotaD: ", rotaD.length);
  const emp = [
    {
        "idEmpresa": 1,
        "localizacaoIdLocalizacao": 1,
        "nome": "Empresa A",
        "cnpj": "12345678901234",
        "telefone": "1234567890",
        "email": "empresaA@gmail.com",
        "linkSite": "",
        "criadoPor": "SISTEMA",
        "createdAt": "2023-11-24T20:58:37.998Z",
        "updatedAt": "2023-11-24T20:58:37.998Z",
        "veiculos": [
            {
                "idVeiculo": 1,
                "empresaIdEmpresa": 1,
                "rotaIdRota": 1,
                "moduloGpsIdModuloGps": 1,
                "perfilUsuarioIdPerfilUsuario": 1,
                "ativo": false,
                "placa": "ABC-1234",
                "modelo": "Buzão Mercede",
                "numeroSerie": "123456789",
                "lotacao": 50,
                "createdAt": "2023-11-24T20:58:38.054Z",
                "updatedAt": "2023-11-24T20:58:38.054Z"
            }
        ],
        "linhas": [
            {
                "idRota": 1,
                "empresaIdEmpresa": 1,
                "ativa": false,
                "nome": "Rota A",
                "numero": "1A",
                "linhaRota": [
                    "-54.11851285343748 -31.32920714987207",
                    "-54.114850000239244 -31.33173147811248",
                    "-54.114517013585214 -31.33535785933926",
                    "-54.109230850446465 -31.334931233506396",
                    "-54.110354680405294 -31.328211621530038",
                    "-54.11851285343748 -31.32920714987207"
                ],
                "distancia": 0,
                "tempoEstimado": 35,
                "createdAt": "2023-11-24T20:58:38.015Z",
                "updatedAt": "2023-11-24T20:58:38.015Z"
            },
            {
                "idRota": 3,
                "empresaIdEmpresa": 1,
                "ativa": false,
                "nome": "Rota C",
                "numero": "2A",
                "linhaRota": [
                    "-54.1087657 -31.3143782",
                    "-54.1096240 -31.3145386",
                    "-54.1089964 -31.3180812",
                    "-54.1075587 -31.3178795",
                    "-54.1080952 -31.3143232",
                    "-54.1087174 -31.3143599"
                ],
                "distancia": 0,
                "tempoEstimado": 35,
                "createdAt": "2023-11-24T20:58:38.015Z",
                "updatedAt": "2023-11-24T20:58:38.015Z"
            }
        ]
    },
    {
        "idEmpresa": 2,
        "localizacaoIdLocalizacao": 2,
        "nome": "Empresa B",
        "cnpj": "12345678901234",
        "telefone": "1234567890",
        "email": "empresaB@gmail.com",
        "linkSite": "",
        "criadoPor": "SISTEMA",
        "createdAt": "2023-11-24T20:58:37.998Z",
        "updatedAt": "2023-11-24T20:58:37.998Z",
        "veiculos": [
            {
                "idVeiculo": 2,
                "empresaIdEmpresa": 2,
                "rotaIdRota": 2,
                "moduloGpsIdModuloGps": 2,
                "perfilUsuarioIdPerfilUsuario": 2,
                "ativo": false,
                "placa": "ABC-1235",
                "modelo": "Buzão Mercede",
                "numeroSerie": "123456789",
                "lotacao": 50,
                "createdAt": "2023-11-24T20:58:38.054Z",
                "updatedAt": "2023-11-24T20:58:38.054Z"
            }
        ],
        "linhas": [
            {
                "idRota": 2,
                "empresaIdEmpresa": 2,
                "ativa": false,
                "nome": "Rota B",
                "numero": "1B",
                "linhaRota": [
                    "-54.10890638024297 -31.328064683320306",
                    "-54.108507195704306 -31.330354127078884",
                    "-54.10810801116567 -31.333325449768154",
                    "-54.105142640305004 -31.333033192679615",
                    "-54.105598851206054 -31.33011057187882",
                    "-54.10594100938235 -31.3277724098961",
                    "-54.10890638024297 -31.328064683320306"
                ],
                "distancia": 0,
                "tempoEstimado": 35,
                "createdAt": "2023-11-24T20:58:38.015Z",
                "updatedAt": "2023-11-24T20:58:38.015Z"
            }
        ]
    }
  ];
  async function BuscaEmpresasAtivas(){
    //const req = await UsuarioApi.BuscarEmpresasAtivas();
    //console.log("BuscaEmpresas: ", req);
    if(true){//req.status){
      setSttPg({
        ...sttPg,
        empresas : emp,
      });
    } else {
      Alerts.aviso("Atenção", messagens.erroComunic);
    }
    setLoading(false);
  }

  function SalvarLocation(position){
    const body = { 
      position: {
        lat         : position.coords.latitude,
        lng         : position.coords.longitude,
        accuracy    : position.coords.accuracy,
        altitude    : position.coords.altitude,
        altitudeAcc : position.coords.altitudeAccuracy,
        heading     : position.coords.heading,
        speed       : position.coords.speed,
        timestamp   : position.timestamp,
      }, 
      caminho       : "motorista" 
    };
    console.log("SalvarLocation: ", body);
    const req = UsuarioApi.EnviarOperacao(body);
  }

  React.useEffect(() => {
    actions.SetaModeContext("acess-client");
    BuscaEmpresasAtivas();
    document.title = "Buscar Rota | Move Bus";

    let watchId;

    const opcoes = {
      // Opções como a precisão podem ser configuradas aqui
      enableHighAccuracy  : true,   // Se precisar de uma precisão maior
      maximumAge          : 15000,  // Idade máxima em milissegundos de um cache de posição
      timeout             : 15000,  // Tempo máximo em milissegundos para obter a localização
    };

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (posicao) => {
          console.log("Permissão concedida. Posição atualizada: ", posicao);
          setSttPg(prevSttPg => ({
            ...prevSttPg,
            position: {
              cords: posicao.coords,
              lat: posicao.coords.latitude,
              lng: posicao.coords.longitude,
            },
          }));
          if(userMarkers) userMarkers.setPosition(sttPg.position);
          SalvarLocation(posicao);
        },
        (erro) => {
          console.error("Permissão negada ou erro ao observar a posição: ", erro);
        },
        opcoes
      );

      // ...

      watchId = navigator.geolocation.watchPosition(
        (posicao) => {
          console.log("Posição atualizada: ", posicao);
          const newPosition = {
            cords: posicao.coords,
            lat: posicao.coords.latitude,
            lng: posicao.coords.longitude,
          };
          // Primeiro atualize a posição do marcador...
          if(userMarkers) {
            userMarkers.setPosition(new window.google.maps.LatLng(newPosition.lat, newPosition.lng));
          }
          SalvarLocation(posicao);
          // ...então atualize o estado
          setSttPg(prevSttPg => ({
            ...prevSttPg,
            position: newPosition,
          }));
        },
        (erro) => {
          console.error("Erro ao observar a posição: ", erro);
        },
        opcoes
      );



    } else {
      console.error("Geolocalização não suportada pelo seu navegador.");
    }

    // Limpeza: parar de observar a posição quando o componente for desmontado
    return () => {
      if (watchId) {
        navigator.geolocation.clearWatch(watchId);
      }
    };

  }, []);
  
  
  

  React.useEffect(() => {
    console.log("Google maps carregado", window.google);
    const loader = new Loader({
      apiKey: process.env.REACT_APP_MAPS_KEY,
      version: "weekly",
      libraries: ["places"]
    });
    
    loader.load().then(() => {
      const map = new window.google.maps.Map(mapRef.current, {
        center: sttPg.position,
        zoom: 15,
      });
      mapInstanceRef.current = map;
      // Marcando o ícone no mapa com base na coordenada fornecida
      const userMarker = new window.google.maps.Marker({
        position: sttPg.position,
        map: map,
        icon: {
          url: gpsUser, // url do ícone
          scaledSize: new window.google.maps.Size(50, 50) // Define o tamanho do ícone
        }
      });
      setMarkers(userMarker);
      //console.log("SttPg: maps", sttPg);
      // Verificando se o objeto linhaSel e suas coordenadas estão disponíveis
      if(sttPg.linhaSel === "" || !sttPg.linhaSel.linhaRota) return;
      // Convertendo a string de coordenadas para um array de pontos, para uso sem PostGIS...
      const routeCoords     = Services.ConverteStringToPoints(sttPg.linhaSel.linhaRota);
      console.log("Rota: ", routeCoords);
      //const routeCoords = sttPg.linhaSel.linhaRota.coordinates.map(coord => {return { lat: coord[1], lng: coord[0] }});

      // Criando uma Polyline para desenhar a rota no mapa
      new window.google.maps.Polyline({
        path          : routeCoords,
        geodesic      : true,
        strokeColor   : '#FF0000', // Cor da linha. Pode alterar conforme sua necessidade
        strokeOpacity : 1.0,
        strokeWeight  : 2,
      }).setMap(map);
    });

  }, [sttPg.linhaSel]); // Removemos window.google da lista de dependências

  React.useEffect(() => {
    // ...
    // Aqui você pode inicializar o marcador se ele ainda não estiver definido
    if (!userMarkers && mapInstanceRef.current && sttPg.position) {
      const userMarker = new window.google.maps.Marker({
        position  : sttPg.position,
        map       : mapInstanceRef.current,
        icon : {
          url: gpsUser, // url do ícone
          scaledSize: new window.google.maps.Size(50, 50) // Define o tamanho do ícone
        }
      });
      setMarkers(userMarker);
    }
  
    // E aqui você pode atualizar a posição do marcador se ele já estiver definido
    if (userMarkers) {
      userMarkers.setPosition(new window.google.maps.LatLng(sttPg.position.lat, sttPg.position.lng));
    }
  
    // ...
  
  }, [sttPg.position]); // Dependendo da posição atual para recriar o marcador
  //...[Resto do código abaixo não modificado]
  console.log("SttPg: ", sttPg);
  async function ConectToService(){
    console.log("Conectando ao serviço de rotas")
    const newSocket = socketIoClient("http://192.168.0.23:3005/", {
      // configurara no-cors
      



    });

    //mostrar console de erro
    newSocket.on("error", (err) => {
      console.log("Erro ao conectar ao serviço de rotas", err);
    });

    newSocket.on("connect", () => {
      console.log("Conectado ao serviço de rotas");
    });

    newSocket.on("disconnect", () => {
      console.log("Desconectado do serviço de rotas");
    });

  }

  return (
    <div 
      className = "contain-max-center"
      style     = {{ backgroundColor: "#114764"}}
    >
      {
        loading ? 
        <div className = "contain-loading">
          <Loading
            mode = {true}
            text = {"Carregando rotas..."}
          />
        </div>
          : 
        <div className = "contain-seleciona-empresa-linha">
          <div className = "contain-inputs-selects">
            <CompSelect
              value = {sttPg.empresaSel}
              onChange = {(e) => {
                console.log("Empresa selecionada", e, e.target.value);
                setSttPg({
                  ...sttPg,
                  empresaSel : sttPg.empresas[e.target.value],
                  linhaSel   : "",
                });
              }}
              placeHolder = {"Selecione uma empresa"}
              array       = {sttPg.empresas}
            />
            { //sttPg.empresaSel &&
              <CompSelect
                value = {sttPg.linhaSel}
                onChange = {(e) => {
                  console.log("Linha selecionada", e, e.target.value)
                  setSttPg({
                    ...sttPg,
                    linhaSel : sttPg.empresaSel.linhas[e.target.value],
                  });
                }}
                placeHolder = {"Selecione uma linha"}
                array       = {sttPg.empresaSel.linhas ? sttPg.empresaSel.linhas : []}
              />
            }
          </div>
          
          <div
            ref       = {mapRef}
            className = "contain-map-rota-select"
            style     = {{ width: "100%", height: 'calc(100vh - 200px)'}}
          >
            {/* mostra google maps */}
            
          </div>
          
          <div className = "contain-acess-client-infer">
            <button
              className = "btt-entrar-rotas"
              onClick   = {() => {
                ConectToService();
              }}
            >
              Buscar Rota
            </button>
            <h3 style = {{marginTop: "5px"}}>
              lat: {sttPg.position.lat} / lng: {sttPg.position.lng}
            </h3>
            <h3>
              acc: {sttPg.position.cords ? sttPg.position.cords.accuracy : 0} / 
              alt: {sttPg.position.cords ? sttPg.position.cords.altitude : 0} / 
              altAcc: {sttPg.position.cords ? sttPg.position.cords.altitudeAccuracy : 0} / 
              heading: {sttPg.position.cords ? sttPg.position.cords.heading : 0} / 
              speed: {sttPg.position.cords ? sttPg.position.cords.speed : 0} /
            </h3>
          </div>
        </div>
      }
    </div>
  );
}